import { Box, Stack } from "@mui/material";
import React, { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ReactGA from "./Analytics";
import AccessDenied from "./pages/AccessDenied/AccessDenied";
import Admin from "./pages/Admin/Admin";
// import Feedback from "./pages/Admin/Feedback";
// import Protal from "./pages/Admin/Protal";
import ResetPassword from "./pages/Admin/ResetPassword";
import { getUser } from "./pages/Admin/userSlice";
import EmailVerification from "./pages/EmailVerification/EmailVerification";
// import Editor from "./pages/NewEditor";
import LandingPage from "./pages/landing page";
// import Library from "./pages/library";
// import LiteratureReview from "./pages/literature review/LiteratureDialog";
// import PapersInsights from "./pages/paper insights";
// import SearchPapers from "./pages/papers";
// import Profile from "./pages/profile";
import ProjectsList from "./pages/projects/index";
// import References from "./pages/references";
import Sidebar from "./pages/sidebar";
// import Subscription from "./pages/subscription";
// import TipTapEditor from "./pages/editor";
// import Library from "./pages/library";
// import SearchPapers from "./pages/papers";
// import PapersInsights from "./pages/paper insights";
// import LiteratureReview from "./pages/literature review/LiteratureDialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import API from "./pages/API/API";
import Layout3 from "./pages/API/Sidebar/Layout3";
// import SemanticItems from "./pages/papers/SemanticItems";
import Universities from "./pages/landing page/universities";
import FAQsComp from "./pages/landing page/faqs/index";
import Pricing from "./pages/landing page/pricing";
import Features from "./pages/landing page/features";
import ApiSiderBar from "./pages/API/Sidebar/Layout3";
import TermsAndConditions from "./pages/landing page/term conditions";
import ResearchpalPapers from "./pages/papers/ResearchpalPapers";
import MaintenancePage from "./pages/maintenance";
import Blog from "./pages/landing page/blog";
import BlogDetails from "./pages/landing page/blog/BlogDetails";
import FeatureDetails from "./pages/landing page/features/FeatureDetails";
// import Essay from "./pages/projects/essay";
import ScrollToTop from "./components/ScrollToTop";
import "react-lazy-load-image-component/src/effects/blur.css";
import TailSpinLoaderFull from "./components/TailSpinLoaderFull";
import { ThreeDots } from "react-loader-spinner";

const TipTapEditor = lazy(() => import("./pages/editor"));
const LiteratureReview = lazy(() =>
  import("./pages/literature review/LiteratureDialog")
);
const Essay = lazy(() => import("./pages/projects/essay"));
const References = lazy(() => import("./pages/references"));
const SearchPapers = lazy(() => import("./pages/papers"));
const SemanticItems = lazy(() => import("./pages/papers/SemanticItems"));
const PapersInsights = lazy(() => import("./pages/paper insights"));
const Library = lazy(() => import("./pages/library"));
// const ProjectsList = lazy(() => import("./pages/projects/index"));
const Profile = lazy(() => import("./pages/profile"));
const Subscription = lazy(() => import("./pages/subscription"));
const API = lazy(() => import("./pages/API/API"));
const Protal = lazy(() => import("./pages/Admin/Protal"));
const Feedback = lazy(() => import("./pages/Admin/Feedback"));

function App() {
  const dispatch = useDispatch();
  const loggedIn = localStorage.getItem("loggedIn");
  const pathname = window.location.pathname;
  const { user, isLoggedIn } = useSelector((state) => state.user);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
      title: "App",
    });

    if (loggedIn && user == "") {
      dispatch(getUser());
    }
  }, []);

  useEffect(() => {
    // Parse query parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Check for any of the desired parameters
    // const ref = urlParams.get("ref");
    const via = urlParams.get("via");
    // const aff = urlParams.get("aff");

    // If any of them exist, set `isTolt` in localStorage
    if (via) {
      localStorage.setItem("affiliateReferral", "true");
      console.log("Tolt referral detected, isTolt set to true in localStorage");
    } else {
      console.log("No Tolt referral detected");
    }
  }, []);

  useEffect(() => {
    // TikTok Pixel tracking
    if (window.ttq) {
      window.ttq.track("ViewPage");
    } else {
      console.warn("TikTok Pixel is not initialized.");
    }

    // Facebook Pixel tracking
    if (window.fbq) {
      window.fbq("track", "PageView");
    } else {
      console.warn("Facebook Pixel is not initialized.");
    }
  }, [pathname]);

  // const Projects = () => {
  //   return (
  //     <Routes>
  //       <Route
  //         path="/"
  //         element={
  //           <Suspense fallback={<            <Stack justifyContent="center" alignItems="center">
  //             <ThreeDots
  //               height="80"
  //               width="80"
  //               radius="9"
  //               color="#353535"
  //               ariaLabel="three-dots-loading"
  //               wrapperStyle={{}}
  //               wrapperClassName=""
  //               visible={true}
  //             />
  //           </Stack> />}>
  //             <ProjectsList />
  //           </Suspense>
  //         }
  //       />
  //       <Route path="/:id/*" element={<ProjectDetails />} />
  //     </Routes>
  //   );
  // };

  const Projects = () => {
    return (
      <Routes>
        <Route path="/" element={<ProjectsList />} />
        <Route path="/:id/*" element={<ProjectDetails />} />
      </Routes>
    );
  };

  const ProjectDetails = () => {
    return (
      <Box sx={{ width: "100%", display: "flex", alignItems: "stretch" }}>
        <Box sx={{ flex: "0 0 auto" }}>
          <Sidebar />
        </Box>
        <Box sx={{ flex: "1 1 auto", overflowX: "auto" }}>
          <Suspense fallback={<TailSpinLoaderFull />}>
            <Routes>
              <Route path="*" element={<TipTapEditor />} />
              <Route path="document" element={<TipTapEditor />} />
              <Route path="literature-review" element={<LiteratureReview />} />
              <Route path="essay-writing" element={<Essay />} />
              <Route path="references" element={<References />} />
              <Route path="search-papers" element={<SearchPapers />} />
              <Route path="search-papers/:id" element={<SemanticItems />} />
              <Route path="papers-insights" element={<PapersInsights />} />
              <Route path="library" element={<Library />} />
            </Routes>
          </Suspense>
        </Box>
      </Box>
    );
  };

  const UserRoutes = () => {
    return (
      <Routes>
        <Route
          exact
          path="/subscription/profile"
          element={
            <Suspense fallback={<TailSpinLoaderFull />}>
              <Profile />
            </Suspense>
          }
        />
        <Route exact path="/projects/*" element={<Projects />} />
        <Route
          exact
          path="/subscription"
          element={
            <Suspense fallback={<TailSpinLoaderFull />}>
              <Subscription />
            </Suspense>
          }
        />
        <Route exact path="/projects" element={<ProjectsList />} />
        {/* <Route exact path="/mantainance" element={<MaintenancePage />} /> */}
        <Route path="*" element={<Navigate to="/projects" />} />
      </Routes>
    );
  };
  const AdminRoutes = () => {
    return (
      <Suspense fallback={<TailSpinLoaderFull />}>
        <Routes>
          <Route exact path="/admin/verify-users" element={<Protal />} />
          <Route exact path="/admin/feedback" element={<Feedback />} />
          <Route path="*" element={<Navigate to="/admin/verify-users" />} />
        </Routes>
      </Suspense>
    );
  };
  const LandingRoutes = () => {
    return (
      <>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route
            exact
            path="/email-verification"
            element={<EmailVerification />}
          />
          <Route
            exact
            path="/api"
            element={
              <ApiSiderBar
                children={
                  <Suspense fallback={<TailSpinLoaderFull />}>
                    <API />
                  </Suspense>
                }
              />
            }
          ></Route>
          <Route
            exact
            path="/termsandconditions"
            element={<TermsAndConditions />}
          />
          <Route exact path="/universities" element={<Universities />} />
          <Route exact path="/faqs" element={<FAQsComp />} />
          <Route exact path="/pricing" element={<Pricing />} />
          {/* <Route exact path="/blog" element={<Blog />} /> */}
          {/* <Route path="/blog/:id" element={<BlogDetails />} /> */}
          <Route exact path="/features" element={<Features />} />
          <Route path="/features/:id" element={<FeatureDetails />} />
          <Route exact path="/access-denied" element={<AccessDenied />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/admin" element={<Admin />} />
          {/* <Route exact path="/mantainance" element={<MaintenancePage />} /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </>
    );
  };

  return (
    <Box>
      {!isLoggedIn && (
        <Routes>
          <Route path="/*" element={<LandingRoutes />} />
        </Routes>
      )}
      {isLoggedIn == "user" && (
        <Routes>
          <Route path="/*" element={<UserRoutes />} />
        </Routes>
      )}
      {isLoggedIn == "admin" && (
        <Routes>
          <Route path="/*" element={<AdminRoutes />} />
        </Routes>
      )}
    </Box>
  );
}

export default App;

import React, { lazy, Suspense, useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ChatIcon from "@mui/icons-material/Chat";

import ListItemText from "@mui/material/ListItemText";
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import RefsIcon from "../../assets/images/refs icon.svg";
import RefsIconWhite from "../../assets/images/refs icon white.svg";
import LibIcon from "../../assets/images/lib icon.svg";
import LibIconWhite from "../../assets/images/lib white.svg";
import DocIcon from "../../assets/images/doc icon.svg";
import DocIconWhite from "../../assets/images/doc icon white.svg";
import SearchLight from "../../assets/images/search-light.svg";
import SearchWhite from "../../assets/images/search-white.svg";
import InsIcon from "../../assets/images/insights.svg";
import InsWhite from "../../assets/images/insights white.svg";
// import ResearchpalDocumentLogo from "../../assets/images/NewImages/ResearchpalDocumentLogo.svg";
import ResearchpalDocumentLogo from "../../assets/images/NewImages/sidebar-RP.svg";
import MiniLogo from "../../assets/images/mini logo.svg";
import HomeIcon from "../../assets/images/NewImages/HomeIcon.svg";
import usage from "../../assets/images/NewImages/usageIcon.svg";
import usageHover from "../../assets/images/NewImages/usageHover.svg";
import help from "../../assets/images/NewImages/help.svg";
import helpHover from "../../assets/images/NewImages/helpHover.svg";
import LogoutIcon from "../../assets/images/logout icon.svg";
import LR from "../../assets/images/literature review.svg";
import LRWhite from "../../assets/images/literature review white.svg";
import feedback from "../../assets/images/NewImages/feedback.svg";
import feedbackHover from "../../assets/images/NewImages/feedbackHover.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSidebar,
  handleSelectedCom,
  handleSidebarToggle,
  setUserBoarding,
} from "./toggleSlice";
import ToggleSide from "./ToggleSide";
import { Analytics, AssignmentInd, Help } from "@mui/icons-material";
// import ProductFeatures from "./ProductFeatures";
import FeedbackDialogue from "./FeedbackDialogue";
import useGetUrl from "./useGetUrl";
import { clearUser, getUser } from "../Admin/userSlice";
import Repo from "../../repo/Repo";
// import UsageDialogue from "../profile/UsageDialogue";
import ResearchPal from "../../assets/images/NewImages/ResearchPal.svg";
import {
  clearAllLitReview,
  abortReviewFetch,
} from "../literature review/reviewSlice";
import { clearAllInsights } from "../paper insights/insightsSlice";
import { clearProject } from "../projects/projectSlice";
import documentHover from "../../assets/images/NewImages/documnetHover.svg";
import referencesHover from "../../assets/images/NewImages/referencesHover.svg";
import paperHover from "../../assets/images/NewImages/paperHover.svg";
import searchHover from "../../assets/images/NewImages/searchHover.svg";
import literatureHover from "../../assets/images/NewImages/literatureHover.svg";
import libraryHover from "../../assets/images/NewImages/libraryHover.svg";
import homePageHover from "../../assets/images/NewImages/homePageHover.svg";
import newLogout from "../../assets/images/NewImages/newLogout.svg";
import newLogoutHover from "../../assets/images/NewImages/newLogoutHover.svg";
import essay from "../../assets/images/essay.svg";
import essayHover from "../../assets/images/essayHover.svg";
import essayWhite from "../../assets/images/essayWhite.svg";
import TailSpinLoader from "../../components/TailSpinLoader";
const ProductFeatures = lazy(() => import("./ProductFeatures"));
const UsageDialogue = lazy(() => import("../profile/UsageDialogue"));

const drawerWidth = "232px";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  background: "#FFFFFF",
  // boxShadow: "0px 3.57397px 40.2071px rgba(0, 0, 0, 0.08)",
  borderRight: "1px solid #E5E5E5",
  height: "100vh",
  // padding: "0px 24px",
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MainStyle = styled(Box)((props) => ({
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100vh",
  padding: props.open ? "8px 24px 0 24px" : "8px 0 0 0",
  width: props.open ? "100%" : "32px",
}));
const LogoBox = styled(Box)({
  display: "flex",
  borderRadius: "4px",
  marginBottom: "8px",
  justifyContent: "space-between",
  alignItems: "center",
});

function Sidebar() {
  const { lastSearchPapersRoute } = useSelector((state) => state.routes);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEssaySelected, setIsEssaySelected] = useState(false);
  const { sidebarOpen, selectedCom } = useSelector((state) => state.sidebar);
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const [activeLink, setActiveLink] = useState("");
  const [openDialogue, setOpenDialogue] = useState(false);
  const [state, setState] = useState({
    openDialogue: false,
    openFeedBackDialogue: false,
  });
  const [openUsageDialogue, setOpenUsageDialogue] = useState(false);

  const [newData, setNewData] = useState({});
  const theme = useTheme();

  const isExactMedium = useMediaQuery(theme.breakpoints.up("md"));
  const [joyrideSteps, setJoyrideSteps] = useState([]);
  const [userBoarding, setUserBoarding] = useState(false);
  const isBelowMedium = useMediaQuery(theme.breakpoints.down("md"));
  const [isEssayClicked, setIsEssayClicked] = useState(false);

  const getUrl = useGetUrl(); //search-papers

  let justifyContent = sidebarOpen ? "initial" : "center";
  const ItemBox = styled(Box)((props) => ({
    display: "flex",
    minHeight: "30px",
    alignItems: "center",
    borderRadius: "4px",
    marginBottom: "8px",
    justifyContent: sidebarOpen ? "initial" : "center",
    background:
      props.curr === getUrl
        ? "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)"
        : "#f6f6f6",
    color: props.curr === getUrl ? "#FFF" : "#7A7979",
    cursor: "pointer",
    "&:hover": {
      background:
        props.curr === getUrl
          ? "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)"
          : "#EFEFEF",
      color: props.curr === getUrl ? "#FFF" : "#14224B",
      //q:i want to chaneg icon on hover
      //a: use the below code
      "& img": {
        content: props.curr !== getUrl && `url(${props.hoverImage})`,
      },
    },
  }));

  const NewBadge = styled("span")({
    backgroundColor: "#FF5733", // Bright color for "New" text
    color: "#fff",
    padding: "2px 4px",
    borderRadius: "4px",
    marginLeft: "2px",
    fontSize: "10px",
    marginLeft: "8px",
    opacity: 0,
    animation: isEssayClicked ? "none" : "blink 1.5s ease-in-out infinite",
    position: "absolute",
    top: "5.5px",
    "@keyframes blink": {
      "0%": {
        opacity: 0,
      },
      "50%": {
        opacity: 1,
      },
      "100%": {
        opacity: 0,
      },
    },
  });

  // useEffect(() => {
  //   const steps = {
  //     document: DocumentSteps,
  //     "literature-review": [],
  //     references: [],
  //     "search-papers": [],
  //     "papers-insights": [],
  //     library: [],
  //   };
  //   setJoyrideSteps(steps);
  // }, []);
  useEffect(() => {
    getProjects();
    if (isBelowMedium && sidebarOpen) {
      toggle();
    } else if (isExactMedium && !sidebarOpen) {
      dispatch(handleSidebarToggle());
    }
  }, [isBelowMedium, isExactMedium]);

  // useEffect(() => {
  //   const hasFeedbackOpened = localStorage.getItem("hasFeedbackOpened");

  //   if (hasFeedbackOpened !== "true") {
  //     localStorage.setItem("hasFeedbackOpened", "true");
  //     const tenMinutes = 20 * 60 * 1000;
  //     setTimeout(() => {
  //       console.log("1");
  //       setState((prev) => ({ ...prev, openFeedBackDialogue: true }));
  //     }, tenMinutes);
  //   }
  // }, []);

  useEffect(() => {
    const storedSelectedCom = localStorage.getItem("selectedComponent");
    if (storedSelectedCom) {
      dispatch(handleSelectedCom(storedSelectedCom));
    }
  }, [dispatch]);

  const toggle = () => {
    dispatch(closeSidebar());
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    // dispatch(clearAllInsights());
    dispatch(clearAllLitReview());
    dispatch(clearProject());
    navigate("/");
  };

  // const handleHomepageClick = () => {
  //   dispatch(abortReviewFetch());
  // };
  const handleHomePage = () => {
    dispatch(getUser());
  };

  const getProjects = async () => {
    try {
      // setLoading(true);
      let { data } = await Repo.research({
        request: {
          method: "getProjectsById",
          data: {
            _id: localStorage.getItem("projectId"),
          },
        },
      });
      // setLoading(false);
      if (data.response.data.projects) {
        setNewData(data.response.data.projects);
        // localStorage.setItem(
        //   "abstracts",
        //   JSON.stringify(data.response.data.projects?.abstracts)
        // );
        // setAbstracts(data.response.data.projects?.abstracts || "");
        // console.log(data.response.data);
      }
    } catch (err) {
      // setLoading(false);
      console.log(err);
    }
  };

  const iconStyle = {
    color: "#FFF",
    pl: sidebarOpen ? 1.6 : 0,
    minWidth: 0,
    mr: sidebarOpen ? 0.8 : "auto",
    ml: sidebarOpen ? 0 : 0.8,
    justifyContent: "center",
  };

  const SubTitle = styled(ListItemText)({
    fontWeight: 500,
    fontSize: "5px",
    cursor: "pointer",
    opacity: sidebarOpen ? 1 : 0,
  });

  const handleDialogue = (name) => {
    setState((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handleUsage = () => {
    setOpenUsageDialogue(true);
  };

  // const handleRedirect = (e) => {
  //   let url = "";
  //   url = newData?.title.toString().toLowerCase().replace(/\s+/g, "-");
  //   navigate(`/projects/${url}/${e}`);
  //   //setACtiveLink when the link is clicked
  // };

  const handleRedirect = (e) => {
    if (e === "essay-writing") {
      setIsEssayClicked(true); // Stop the animation
    }
    // else {
    //   // setIsEssayClicked(false);
    // }
    let url = newData?.title.toString().toLowerCase().replace(/\s+/g, "-");

    if (e === "search-papers") {
      if (lastSearchPapersRoute) {
        navigate(lastSearchPapersRoute);
      } else {
        navigate(`/projects/${url}/${e}`);
      }
    } else {
      navigate(`/projects/${url}/${e}`);
    }
  };

  useEffect(() => {
    if ("*" in params) {
      setActiveLink(params["*"]);
    }
  }, [params]);

  useEffect(() => {
    if (user.onboardingProject === true) {
      // dispatch(setUserBoarding(true));
      setUserBoarding(true);
    }
  }, [user.onboardingProject]);
  return (
    <DrawerBox
      variant="permanent"
      open={sidebarOpen}
      sx={{ position: "relative" }}
    >
      {/* {joyrideSteps[activeLink] && (
        <Joyride
          steps={joyrideSteps[activeLink]}
          continuous
          hideCloseButton
          showProgress
          disableOverlayClose
          styles={JoyrideOptions}
          run={userBoarding}
          callback={(data) => {
            if (data.status === "finished") {
              dispatch(setIsPopoverOpen(true));
              dispatch(
                setPopoverPosition({
                  top: window.innerHeight / 2 - 250,
                  left: window.innerWidth / 2 - 400,
                })
              );
            }
            if (data.index === 1) {
              localStorage.setItem("aiButtonColor", true);
              localStorage.setItem("sideBarBoarding", true);
            }
          }}
        />
      )} */}
      <MainStyle open={sidebarOpen}>
        <List>
          <ListItem
            disablePadding
            sx={{ display: "block", borderRadius: "4px", mb: 1.6 }}
          >
            <LogoBox>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  width: "100%",
                  mr: sidebarOpen ? 0 : "auto",
                  justifyContent: "center",
                }}
              >
                {sidebarOpen ? (
                  <Stack
                    direction="row"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack
                      direction="row"
                      sx={{ width: "60%" }}
                      alignItems={"center"}
                      spacing={1}
                    >
                      <img
                        src={ResearchpalDocumentLogo}
                        alt="ResearchPal logo"
                      />
                      <img src={ResearchPal} alt="ResearchPal logo" />
                    </Stack>
                    <ToggleSide />
                  </Stack>
                ) : (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                  >
                    <ToggleSide />
                    <img src={ResearchpalDocumentLogo} alt="ResearchPal logo" />
                  </Stack>
                )}
              </ListItemIcon>
            </LogoBox>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr="document"
              onClick={() => handleRedirect("document")}
              id="open-document"
              hoverImage={documentHover}
            >
              <ListItemIcon sx={iconStyle}>
                <Tooltip title={sidebarOpen ? "" : "Editor"} placement="right">
                  <img
                    style={{ height: "24px", width: "24px" }}
                    src={getUrl == "document" ? DocIconWhite : DocIcon}
                    alt=""
                  />
                </Tooltip>
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontFamily: "Raleway", fontSize: "14px" },
                }}
              >
                Document
              </SubTitle>
            </ItemBox>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr="literature-review"
              onClick={() => handleRedirect("literature-review")}
              id="literature-review"
              hoverImage={literatureHover}
            >
              <ListItemIcon sx={iconStyle}>
                <Tooltip
                  title={sidebarOpen ? "" : "Literature Review"}
                  placement="right"
                >
                  <img
                    style={{ height: "24px", width: "24px" }}
                    src={getUrl == "literature-review" ? LRWhite : LR}
                    alt=""
                  />
                </Tooltip>
              </ListItemIcon>

              <SubTitle
                primaryTypographyProps={{
                  style: { fontFamily: "Raleway", fontSize: "14px" },
                }}
              >
                Literature Review
              </SubTitle>
            </ItemBox>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr="essay-writing"
              onClick={() => handleRedirect("essay-writing")}
              hoverImage={essayHover}
            >
              <ListItemIcon sx={iconStyle}>
                <Tooltip
                  title={sidebarOpen ? "" : "Essay Writing"}
                  placement="right"
                >
                  <img
                    style={{ height: "24px", width: "24px" }}
                    src={getUrl == "essay-writing" ? essayWhite : essay}
                    alt=""
                  />
                </Tooltip>
              </ListItemIcon>

              <SubTitle
                primaryTypographyProps={{
                  style: { fontFamily: "Raleway", fontSize: "14px" },
                }}
              >
                Essay Writing
                {/* <NewBadge className="new-badge">New</NewBadge> */}
              </SubTitle>
            </ItemBox>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr="references"
              onClick={() => handleRedirect("references")}
              hoverImage={referencesHover}
            >
              <ListItemIcon sx={iconStyle}>
                <Tooltip
                  title={sidebarOpen ? "" : "References"}
                  placement="right"
                >
                  <img
                    style={{ height: "24px", width: "24px" }}
                    src={getUrl == "references" ? RefsIcon : RefsIconWhite}
                    alt=""
                  />
                </Tooltip>
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "14px", fontFamily: "Raleway" },
                }}
              >
                References
              </SubTitle>
            </ItemBox>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr={
                getUrl == "search-papers"
                  ? "search-papers"
                  : "search-papers/:id"
              }
              onClick={() => handleRedirect("search-papers")}
              hoverImage={searchHover}
            >
              <ListItemIcon sx={iconStyle}>
                <Tooltip
                  title={sidebarOpen ? "" : "Search Papers"}
                  placement="right"
                >
                  <img
                    style={{ height: "24px", width: "24px" }}
                    src={getUrl == "search-papers" ? SearchWhite : SearchLight}
                    alt=""
                  />
                </Tooltip>
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "14px", fontFamily: "Raleway" },
                }}
              >
                Search Papers
              </SubTitle>
            </ItemBox>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr="papers-insights"
              onClick={() => handleRedirect("papers-insights")}
              hoverImage={paperHover}
            >
              <ListItemIcon sx={iconStyle}>
                <Tooltip
                  title={sidebarOpen ? "" : "Papers Insights"}
                  placement="right"
                >
                  <img
                    style={{ height: "24px", width: "24px" }}
                    src={getUrl == "papers-insights" ? InsWhite : InsIcon}
                    alt=""
                  />
                </Tooltip>
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "14px", fontFamily: "Raleway" },
                }}
              >
                Paper Insights
              </SubTitle>
            </ItemBox>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr="library"
              onClick={() => handleRedirect("library")}
              hoverImage={libraryHover}
            >
              <ListItemIcon sx={iconStyle}>
                <Tooltip title={sidebarOpen ? "" : "Library"} placement="right">
                  <img
                    style={{ height: "24px", width: "24px" }}
                    src={getUrl == "library" ? LibIconWhite : LibIcon}
                    alt=""
                  />
                </Tooltip>
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "14px", fontFamily: "Raleway" },
                }}
              >
                Library
              </SubTitle>
            </ItemBox>
          </ListItem>
        </List>
        {/* {sidebarOpen &&
          (user.subscription?.planName === "free-plan" ||
            user.subscription?.planName === "standard") && (
            <List>
              <ListItem disablePadding sx={{ display: "block" }}>
                <Stack
                  justifyContent={"center"}
                  sx={{
                    background: "#f6f6f6",
                    padding: "8px",
                    width: "100%",
                    wordBreak: "break-word",
                    wordWrap: "break-word",
                  }}
                  spacing={1}
                >
                  <>
                    <Typography
                      sx={{
                        color: "#4E4E4E",
                        fontSize: "14px",
                        fontFamily: "Raleway",
                      }}
                      textAlign={"center"}
                    >
                      {user.subscription?.planName === "free-plan"
                        ? "Upgrade to the Standard plan"
                        : "Upgrade to ResearchPal Pro"}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#4E4E4E",
                        fontSize: "14px",
                        fontFamily: "Raleway",
                      }}
                      textAlign={"center"}
                    >
                      {user.subscription?.planName === "free-plan"
                        ? "to use Claude 3"
                        : "to use (GPT-4 Turbo & Claude  "}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#4E4E4E",
                        fontSize: "14px",
                        fontFamily: "Raleway",
                      }}
                      textAlign={"center"}
                    >
                      {user.subscription?.planName === "free-plan"
                        ? "and unlock more usage"
                        : "Opus) and unlock more usage"}
                    </Typography>
                  </>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#353535 !important",
                        justifyContent: "center",
                        height: "25px !important",
                        fontFamily: "Raleway",
                        fontSize: "14px",
                        width: "50%",
                        textTransform: "none",
                      }}
                      onClick={() => navigate("/subscription")}
                    >
                      Subscribe
                    </Button>
                  </Box>
                </Stack>
              </ListItem>
            </List>
          )} */}
        <List>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => handleDialogue("openFeedBackDialogue")}
          >
            <ItemBox
              hoverImage={feedbackHover}
              sx={{
                justifyContent,
                // background: "linear-gradient(90deg, #5671F1 0%, #17CEAD 100%)",
                // ":hover": {
                //   background:
                //     "linear-gradient(90deg, #3950CE 0%, #15C3A3 100%)",
                // },
              }}
              // className="continuous-animated-button"
            >
              <ListItemIcon sx={iconStyle}>
                <Tooltip
                  title={sidebarOpen ? "" : "Feedback"}
                  placement="right"
                >
                  {/* <ChatIcon sx={{ height: "24px", width: "24px" }} /> */}
                  <img
                    src={feedback}
                    alt=""
                    style={{
                      height: "24px",
                      width: "24px",
                      marginRight: "2px",
                      padding: "2px",
                    }}
                  />
                </Tooltip>
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "14px", fontFamily: "Raleway" },
                }}
                // sx={{ color: "#fff" }}
                sx={{
                  color: "#4E4E4E",
                  "&:hover": {
                    color: "#14224B",
                  },
                }}
              >
                Feedback
              </SubTitle>
            </ItemBox>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => handleDialogue("openDialogue")}
          >
            <ItemBox
              sx={{
                justifyContent,
                background: "#f6f6f6",
                "&:hover": {
                  color: "#14224B",
                },
              }}
              hoverImage={helpHover}
            >
              <ListItemIcon sx={iconStyle}>
                <Tooltip title={sidebarOpen ? "" : "Help"} placement="right">
                  <img
                    style={{ height: "24px", width: "24px" }}
                    src={help}
                    alt=""
                  />
                </Tooltip>
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "14px", fontFamily: "Raleway" },
                }}
                sx={{
                  color: "#4E4E4E",
                  "&:hover": {
                    color: "#14224B",
                  },
                }}
              >
                Help
              </SubTitle>
            </ItemBox>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/projects">
              <ItemBox
                sx={{ justifyContent, background: "#f6f6f6" }}
                onClick={handleHomePage}
                hoverImage={homePageHover}
              >
                <ListItemIcon sx={iconStyle}>
                  <Tooltip
                    title={sidebarOpen ? "" : "Homepage"}
                    placement="right"
                  >
                    <img
                      style={{ height: "24px", width: "24px" }}
                      src={HomeIcon}
                      alt=""
                    />
                  </Tooltip>
                </ListItemIcon>
                <SubTitle
                  primaryTypographyProps={{
                    style: { fontSize: "14px", fontFamily: "Raleway" },
                  }}
                  sx={{
                    color: "#4E4E4E",

                    "&:hover": {
                      color: "#14224B",
                    },
                  }}
                >
                  Homepage
                </SubTitle>
              </ItemBox>
            </Link>
          </ListItem>
          {/* <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/subscription/profile">
              <ItemBox sx={{ justifyContent, background: "#f6f6f6" }}>
                <ListItemIcon sx={iconStyle}>
                  <Tooltip
                    title={sidebarOpen ? "" : "Profile"}
                    placement="right"
                  >
                    <AssignmentInd
                      sx={{ fontSize: "16px", mr: 0.5, color: "#4E4E4E" }}
                    />
                  </Tooltip>
                </ListItemIcon>
                <SubTitle
                  primaryTypographyProps={{
                    style: { fontSize: "14px", fontFamily: "Raleway" },
                  }}
                  sx={{ color: "#4E4E4E" }}
                >
                  Profile
                </SubTitle>
              </ItemBox>
            </Link>
          </ListItem> */}
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox onClick={handleUsage} hoverImage={usageHover}>
              <ListItemIcon sx={iconStyle}>
                <Tooltip title={sidebarOpen ? "" : "Usage"} placement="right">
                  <img
                    style={{ height: "24px", width: "24px" }}
                    src={usage}
                    alt=""
                  />
                </Tooltip>
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "14px", fontFamily: "Raleway" },
                }}
                sx={{
                  color: "#4E4E4E",
                  "&:hover": {
                    color: "#14224B",
                  },
                }}
              >
                Usage
              </SubTitle>
            </ItemBox>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              sx={{ justifyContent, background: "#f6f6f6" }}
              onClick={handleLogout}
              hoverImage={newLogoutHover}
            >
              <ListItemIcon sx={iconStyle}>
                <Tooltip title={sidebarOpen ? "" : "Logout"} placement="right">
                  <img
                    style={{ height: "24px", width: "24px" }}
                    src={newLogout}
                    alt=""
                  />
                </Tooltip>
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "14px", fontFamily: "Raleway" },
                }}
                sx={{
                  color: "#4E4E4E",
                  "&:hover": {
                    color: "#14224B",
                  },
                }}
              >
                Logout
              </SubTitle>
            </ItemBox>
          </ListItem>
        </List>
      </MainStyle>
      {state.openDialogue && (
        <Suspense fallback={<TailSpinLoader />}>
          <ProductFeatures
            openDialogue={state.openDialogue}
            onClose={(data) => handleDialogue(data)}
          />
        </Suspense>
      )}
      {state.openFeedBackDialogue && (
        <FeedbackDialogue
          openDialogue={state.openFeedBackDialogue}
          onClose={(data) => handleDialogue(data)}
        />
      )}

      {openUsageDialogue && (
        <Suspense fallback={<div>Loading....</div>}>
          <UsageDialogue
            open={openUsageDialogue}
            setOpen={setOpenUsageDialogue}
            user={user}
          />
        </Suspense>
      )}
    </DrawerBox>
  );
}

export default Sidebar;

import { Games } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { BtnSub, PlanCard, PriceTitle, SubTitle, Text } from "./styles";

function FreeCard() {
  const { user } = useSelector((state) => state.user);
  return (
    <PlanCard
      spacing={1.6}
      sx={{
        pb: "10px",
        justifyContent: !user ? "space-around" : "space-between",
      }}
    >
      <Stack spacing={1} alignItems={"center"}>
        <SubTitle>
          <Games sx={{ marginRight: "4px" }} />
          Free
        </SubTitle>
        <PriceTitle
          sx={{
            fontFamily: "sans-serif !important",
            fontSize: "2.25rem",
          }}
        >
          $0
        </PriceTitle>
        <PriceTitle sx={{ color: "#A2B0B7", fontSize: "15px" }}>
          Free forever
        </PriceTitle>
        <PriceTitle sx={{ fontWeight: "700", fontSize: "13px" }}>
          Good for testing the product
        </PriceTitle>
      </Stack>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {/* {user?.subscription?.planName === "free-plan" ? (
          <BtnSubscribed disabled sx={{ marginBottom: "10px" }}>
            <Text sx={{ fontSize: "18px", color: "#FFFFFF" }}>Subscribed</Text>
          </BtnSubscribed>
        ) : ( */}
        <BtnSub
          variant="contained"
          disabled
          sx={{
            cursor: "default",
            background:
              user?.subscription?.planName === "standard"
                ? "#f3f3f3"
                : "#1E4F6B !important",
            marginBottom: "10px",
          }}
        >
          <Text
            sx={{
              fontSize: "18px",
              color:
                user?.subscription?.planName === "standard"
                  ? "#00000"
                  : "#fff !important",
              fontFamily: "Raleway",
            }}
          >
            Free Plan
          </Text>
        </BtnSub>
        {/* )} */}
      </Box>
    </PlanCard>
  );
}

export default FreeCard;

import React from "react";
import { Stack } from "@mui/material";
import { TailSpin } from "react-loader-spinner";

function TailSpinLoaderFull() {
  return (
    <Stack
      width="100%"
      height="100vh" // ✅ Full height
      alignItems="center" // ✅ Center vertically
      justifyContent="center" // ✅ Center horizontally
    >
      <TailSpin
        height="50"
        width="50"
        color="#5AB7BF"
        ariaLabel="tail-spin-loading"
        radius="1"
        visible={true}
      />
    </Stack>
  );
}

export default TailSpinLoaderFull;

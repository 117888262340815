import {
  Box,
  Grid,
  Stack,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React from "react";
// import editorIcon from "../../../assets/images/editorIcon.png";
import editorIcon from "../../../assets/images/editor-icon-new.png";
import textTuner from "../../../assets/images/NewImages/textTuner.png";
import translate from "../../../assets/images/NewImages/translate.png";
import inTextCitation from "../../../assets/images/NewImages/inTextCitation.png";
import grammerCheck from "../../../assets/images/NewImages/grammerCheck.png";
import outlines from "../../../assets/images/NewImages/outlines.png";
import aiImage from "../../../assets/images/NewImages/aiImage.png";
import sources from "../../../assets/images/NewImages/sources.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",

  fontWeight: 600,
  fontSize: "32px",
  color: "#14224B",
  textAlign: "center",
  marginBottom: "10px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));
const data = [
  {
    img: textTuner,
    title: "Text-Tuner",
    description: "Improve the tone of your writing through text tuner.",
  },
  {
    img: translate,
    title: "Translate",
    description: "Translate your text into 30+ languages",
  },
  {
    img: inTextCitation,
    title: "In-Text Citations",
    description:
      "Instantly extract in-text citations from credible journals for your claims.",
    width: "60px",
    height: "42px",
  },
  {
    img: grammerCheck,
    title: "Grammar Check",
    description: "Remove grammatical and spelling errors.",
  },
  {
    img: outlines,
    title: "Outlines",
    description:
      "Generate outlines for your essays, reports and research papers.",
  },
  {
    img: aiImage,
    title: "AI-Image Generation",
    description: "Generate images for your text directly from the editor.",
  },
  // {
  //   img: sources,
  //   title: "Credible Sources",
  //   description:
  //     "Open the source links of references directly from the editor.",
  //   lastItem: true,
  // },
];
const EditorSection = () => {
  const isSmall = useMediaQuery("(max-width:600px)");
  return (
    <Box
      sx={{
        marginTop: "100px",
        marginBottom: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Title variant="h1" sx={{ marginBottom: "20px" }}>
        AI-powered text editor to accelerate academic writing
      </Title>
      {/* <img
        src={editorIcon}
        alt="ResearchPal document section featuring an literature review about Ethical AI & ML. The text selected is being modified using the Text Tuner AI tool of ResearchPal."
        width={850}
        height={550}
        style={{ padding: 8 }}
      /> */}
      <LazyLoadImage
        src={editorIcon}
        alt="ResearchPal document section featuring an literature review about Ethical AI & ML. The text selected is being modified using the Text Tuner AI tool of ResearchPal."
        effect="blur"
        style={{
          width: "100%", // Makes it responsive
          height: "auto", // Maintains aspect ratio
          maxWidth: "850px", // Prevents it from becoming too large
          padding: 8,
        }}
      />
      <Grid
        container
        mt={4}
        sx={{
          justifyContent: "center",
        }}
      >
        {data.map((item) => (
          <Grid
            item
            xs={12}
            md={5}
            m={2}
            sx={{
              borderBottom: "1px solid #e0e0e0",
              paddingBottom: "20px",
            }}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={1}
              alignItem="center"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  // width: item.width ? item.width : "50px",
                  // height: item.height ? item.height : "42px",
                  width: { xs: "100%", sm: item.width ? item.width : "50px" },
                  height: {
                    xs: "auto",
                    sm: item.height ? item.height : "42px",
                  },
                  padding: "5px",
                  alignItems: "center",
                }}
              >
                {/* <img
                  src={item.img}
                  alt="placeholder"
                  width={"40px"}
                  // height={"42px"}
                /> */}
                <LazyLoadImage
                  src={item.img}
                  alt="placeholder"
                  width={"38px"}
                  effect="blur"
                />
              </Box>
              <Stack>
                <Typography color={"#14224B"} fontWeight={600}>
                  {item.title}
                </Typography>
                <Typography color={"#14224B"}>{item.description}</Typography>
              </Stack>
            </Stack>
          </Grid>
        ))}
        <Grid
          item
          xs={11}
          md={10.4}
          sx={{
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: "20px",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            alignItem="center"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                // width: "50px",
                // height: "42px",
                width: { xs: "100%", sm: "40px" },
                height: { xs: "auto", sm: "42px" },
                padding: "5px",
                alignItems: "center",
              }}
            >
              {/* <img src={sources} alt="placeholder" width={"40px"} /> */}
              <LazyLoadImage
                src={sources}
                alt="placeholder"
                width={"40px"}
                effect="blur"
              />
            </Box>
            <Stack>
              <Typography color="#14224B" fontWeight={600}>
                Credible Sources{" "}
              </Typography>
              <Typography color="#14224B">
                Open the source links of references directly from the editor.{" "}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      {/* <Grid
        container
        sx={{
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: "20px",
          }}
        >
          <Stack direction="row" spacing={1} alignItem="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={sources} alt="placeholder" width={50} height={42} />
            </Box>
            <Stack spacing={1}>
              <Typography>Credible Sources </Typography>
              <Typography>
                Open the source links of references directly from the editor.{" "}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid> */}
    </Box>
  );
};

export default EditorSection;

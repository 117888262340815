import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import acm from "../../../assets/images/NewImages/acm.png";
import semantic from "../../../assets/images/NewImages/semantic.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",

  fontWeight: 600,
  fontSize: "32px",
  color: "#14224B",
  textAlign: "center",
  marginBottom: "10px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));
const PlatformSection = () => {
  return (
    <Box
      sx={{
        marginTop: "50px",
        marginBottom: "100px",
      }}
    >
      <Title variant="h1">One Platform For All Research Sources</Title>
      <Typography textAlign="center" fontFamily="Raleway" color="#14224B">
        One platform for your students and faculty to access all the academic
        datasources
      </Typography>
      <Grid container mt={4}>
        <Grid
          item
          xs={12}
          md={6}
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              maxWidth: 345,
              borderRadius: "10px",
              boxShadow: "0px 0px 4px rgba(0.25, 0.25, 0, 0.25)",
              marginBottom: "20px",
            }}
          >
            {/* <CardMedia
              sx={{ height: 180, margin: 1, borderRadius: "10px" }}
              image={acm}
              title="green iguana"
              alt="Nature ACM: Association for Computing Machinery"
            /> */}
            <CardMedia
              component={() => (
                <LazyLoadImage
                  src={acm}
                  alt="Nature ACM: Association for Computing Machinery"
                  effect="blur" // Adds blur while loading (you can use "opacity" or "black-and-white" instead)
                  style={{
                    height: "180px",
                    width: "100%",
                    margin: "11px",
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                />
              )}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                color="#1E4F6B"
              >
                Proprietary:
              </Typography>
              <Typography variant="body2" color="#14224B">
                All journals and data sources for which your institution has
                subscriptions e.g. ACM, Nature etc.
              </Typography>
            </CardContent>
            {/* <CardActions>
              <Button size="small">Share</Button>
              <Button size="small">Learn More</Button>
            </CardActions> */}
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Card
            sx={{
              maxWidth: 345,
              borderRadius: "10px",
              boxShadow: "0px 0px 4px rgba(0.25, 0.25, 0, 0.25)",
              marginBottom: "20px",
            }}
          >
            {/* <CardMedia
              sx={{ height: 180, margin: 1, borderRadius: "10px" }}
              image={semantic}
              title="green iguana"
              alt="arXiv and Semantic Scholar logos"
            /> */}
            <CardMedia
              component={() => (
                <LazyLoadImage
                  src={semantic}
                  alt="arXiv and Semantic Scholar logos"
                  effect="blur" // Adds blur while loading (you can use "opacity" or "black-and-white" instead)
                  style={{
                    height: "180px",
                    width: "100%",
                    margin: "11px",
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                />
              )}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                color="#1E4F6B"
              >
                Open access:
              </Typography>
              <Typography variant="body2" color="#14224B">
                Popular open access data sources e.g. Semantic Scholar, Arxiv
                etc.
              </Typography>
            </CardContent>
            {/* <CardActions>
              <Button size="small">Share</Button>
              <Button size="small">Learn More</Button>
            </CardActions> */}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlatformSection;
